import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

const ProfilePic = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "components/Home/profile_pic.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 180, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      alt="Profile picture"
      imgStyle={{ borderRadius: '50%' }}
    />
  )
}

export default ProfilePic
