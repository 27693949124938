import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { colors } from '../../constants/theme'
import ProfilePic from './ProfilePic'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  align-items: center;
`

const ContainerName = styled.span`
  color: ${colors.GREY};
  margin: 25px 0px 0px;
  font-size: 30px;
`

const ContainerAbout = styled.p`
  width: 320px;
  line-height: 30px;
  font-size: 20px;
  margin: 25px 0px;

  /* for smaller screens, decrease the width */
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
`

const Home: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          profile {
            name
            about
            github
            linkedIn
            twitter
          }
        }
      }
    }
  `)

  const { siteMetadata } = data.site
  const { title, profile } = siteMetadata
  return (
    <Container>
      <ProfilePic />
      <ContainerName>{profile.name}</ContainerName>
      <ContainerAbout
        dangerouslySetInnerHTML={{ __html: profile.about }}
      ></ContainerAbout>
    </Container>
  )
}

export default Home
